import  { Suspense, lazy, useEffect} from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { WEBSITE_URL } from "./util/config";

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

// react-query setup
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// My components
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import AuthRoute, { TokenRoute, WithSmithAIRoute, WithoutSmithAIRoute, LoginRequriedRoute } from './components/CustomRoutes';
import IdleTimer from './components/IdleTimer';
import themeFile from './util/theme';

// Landing Pages
import LoadingPage from './pages/UtilityPages/LoadingPage';
import FeedBack from './components/Gadgets/UserFeedBack';
import ErrorBoundary, { ErrorPage } from './pages/UtilityPages/ErrorBoundary';
import { loginUserFromLastSession } from './redux/actions/userAction';

// MaterialUI x-grid license
import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey('2718dc0413f9dc0d1799c8d8e02da610T1JERVI6Mzg1NjYsRVhQSVJZPTE2NzczNjAzNDgwMDAsS0VZVkVSU0lPTj0x');

// Lazy loading
const LoginPage = lazy(() => import('./pages/LoginPage'));
const TermsServicePage = lazy(() => import('./pages/DocumentPages/TermsServicePage'));
const PrivacyNoticePage = lazy(() => import('./pages/DocumentPages/PrivacyNoticePage'));
const PatientConsentPage = lazy(() => import('./pages/DocumentPages/PatientConsentPage'));
const SalivaCollectionInstructionPage = lazy(() => import('./pages/DocumentPages/SalivaCollectionInstructionPage'));
const SampleReceiptPage = lazy(() => import('./pages/DocumentPages/SampleReceiptPage'));
const SampleReportPage = lazy(() => import('./pages/DocumentPages/SampleReportPage'));
const ForgetPasswordPage = lazy(() => import('./pages/UtilityPages/ForgetPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/UtilityPages/ResetPasswordPage'));
const PortalHomePage = lazy(() => import('./pages/PortalPages/PortalHomePage'));
const ApproveTestResultPage = lazy(() => import('./pages/PortalPages/ApproveTestResultPage'));
const PageNotFound = lazy(() => import('./pages/UtilityPages/404Page'));
const LinkExpiredPage = lazy(() => import('./pages/UtilityPages/LinkExpiredPage'));
const UploadCsvPage = lazy(() => import('./pages/PortalPages/UploadCsvPage'));
const DownloadCsvResultPage = lazy(() => import('./pages/PortalPages/DownloadCsvResultPage'));
const GetGroupBookingTokenPage = lazy(() => import('./pages/PortalPages/GetGroupBookingTokenPage'));
const PhysicianApproveOrderPage = lazy(() => import('./pages/PortalPages/PhysicianApproveOrderPage'));
const RetailCalendarPage = lazy(() => import('./pages/PortalPages/RetailCalendarPage'));
const InsuranceQueryAdminPage = lazy(() => import('./pages/PortalPages/InsuranceQueryAdminPage'));
const GroupTestContactFormPage = lazy(() => import('./pages/FormPages/GroupTestContactFormPage'));
const InsuranceFormPage = lazy(() => import('./pages/FormPages/InsuranceFormPage'));
const RescheduleBookingPage = lazy(() => import('./pages/AppointmentPages/RescheduleBookingPage'));
const GroupBookingPage = lazy(() => import('./pages/AppointmentPages/GroupBookingPage'));
const TestResultPage = lazy(() => import('./pages/AppointmentPages/TestResultPage'));
const SimpleResultPage = lazy(() => import('./pages/AppointmentPages/SimpleResultPage'));
const FaqPage = lazy(() => import('./pages/DocumentPages/FaqPage'));
const BookingPage = lazy(() => import('./pages/AppointmentPages/BookingPage'));
const GetOneTimeCouponPage = lazy(() => import('./pages/PortalPages/GetOneTimeCouponPage'));
const AccountSettingsPage = lazy(() => import('./pages/PortalPages/AccountSettingsPage'));
const CheckInPatientsPage = lazy(() => import('./pages/PortalPages/CheckInPatientsPage'));
const GSDInsuranceFormPageV2 = lazy(() => import('./pages/FormPages/GSInsuranceFormPageV2'));
const PatientLinkSubmitSamplePage = lazy(() => import('./pages/AppointmentPages/PatientLinkSubmitSamplePage'));
const UploadFilesPage = lazy(() => import('./pages/PortalPages/UploadFilesPage'));
const AddPatientPage = lazy(() => import('./pages/PortalPages/AddPatientPage'));
const EditPatientPage = lazy(() => import('./pages/PortalPages/EditPatientPage'));
const InsuranceRecordAdminPage = lazy(() => import('./pages/PortalPages/InsuranceRecordAdminPage'));
const UserManagementPage = lazy(() => import('./pages/PortalPages/UserManagementPage'));
const SampleCollectionPage = lazy(() => import('./pages/PortalPages/SampleCollectionPage'));
const ResultHistoryPage = lazy(() => import('./pages/PortalPages/ResultHistoryPage'));
const GroupCalendarPage = lazy(() => import('./pages/PortalPages/GroupCalendarPage'));
const EditInstitutionPage = lazy(() => import('./pages/PortalPages/EditInstitutionPage'));
const EmailVerificationPage = lazy(() => import('./pages/UtilityPages/EmailVerificationPage'));
const FamilySignUpPage = lazy(() => import('./pages/FamilySignUpPage'));
const FamilyMemberPage = lazy(() => import('./pages/FamilyPortal/FamilyMemberPage'));
const FamilyTestResultsPage = lazy(() => import('./pages/FamilyPortal/FamilyTestResultsPage'));
const FamilyNotificationSettingsPage = lazy(() => import('./pages/FamilyPortal/FamilyNotificationSettingsPage'));
const FamilyInsurancePage = lazy(() => import('./pages/FamilyPortal/FamilyInsurancePage'));
const SchoolTestingInformedConsent = lazy(() => import('./pages/DocumentPages/SchoolTestingInformedConsent'));
const SchoolOpenRegisterPage = lazy(() => import('./pages/FormPages/SchoolOpenRegisterPage'));
const FamilyRegistrationQRCodePage = lazy(() => import('./pages/PortalPages/FamilyRegistrationQRCodePage'));
const RecoverUserAccountPage = lazy(()=> import('./pages/PortalPages/RecoverUserAccountPage'));
const GetOrderReceiptPage = lazy(()=> import('./pages/PortalPages/GetOrderReceiptPage'));
const CronJobStatusPage = lazy(()=> import('./pages/PortalPages/CronJobStatusPage'));
const AptitudePassPage = lazy(() => import('./pages/AppointmentPages/AptitudePassPage'));
const CRFFormPage = lazy(()=> import('./pages/FormPages/CRFFormPage'));
const HelpPage = lazy(() => import('./pages/UtilityPages/HelpPage'));
const ReleaseNotesPage = lazy(() => import('./pages/UtilityPages/ReleaseNotes'));
const UpdateStatusPage = lazy(() => import('./pages/UpdateStatusPage'));
const UpdateStatusPageConfirmation = lazy(() => import('./pages/UpdateStatusPageConfirmation'));
const GroupOrderPage = lazy(() => import('./pages/PortalPages/GroupOrderPage'));

export const queryClient =  new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            staleTime: 10 * 1000, // default stale time is 10 seconds
        }
    }
});

const theme = createTheme(themeFile);

// Axios setup
axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL;

// Set interceptors so that blob response turn to json
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result)
                    resolve(Promise.reject(error))
                };
                reader.onerror = () => {
                    reject(error)
                };
                reader.readAsText(error.response.data);
            })
        }
        return Promise.reject(error);
    }
);

/**
 * Register window innerHeight as a global variable
 * Then we set the value in the --vh custom property to the root of the document
 * We listen to the resize event
 * We execute the same script as before
 */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

/**
 * Define the path that only show page itself.
 * Hide nav bar on path = '/s' and /s/.../...
 * eg:
 *  Route: /s/:group/:confNo
 *  File: /pages/AppointmentPages/SimpleResultPage.js
 * @param {string} pathname
 * @returns {boolean}
 */
const HideNavBar = (pathname) => {
    return (
        pathname.match(/^\/s\/.+\/.*$/) ||
        pathname.match(/^\/schools\/insurance\/.+$/) ||
        pathname.match(/^\/b\/submit\/.+$/) ||
        pathname.match(/^\/si\/.+\/.*$/) ||
        pathname.match(/^\/register.*$/) ||
        pathname.match(/^\/ap\/.+\/.*$/) ||
        pathname.match(/^\/updateStatus\/.+$/) ||
        pathname.match(/^\/updateStatusConfirmation\/.+$/)
    ) ? true : false;
}

// Open the website if user enters the / URL
function WebsitePageOutsideReact() {
    window.location.href = WEBSITE_URL;
    return null;
}

function App(props) {
    const { location } = props;

    useEffect(() => {
        store.dispatch(loginUserFromLastSession());
    }, []);

    return <>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <QueryClientProvider client={queryClient}>
                        <ErrorBoundary>
                            <FeedBack/>

                            {!HideNavBar(location.pathname) && <NavBar/>}

                            <Suspense fallback={<LoadingPage/>}>
                                <Switch>
                                    <WithoutSmithAIRoute exact path="/updateStatus/:userId" component={UpdateStatusPage} />
                                    <WithoutSmithAIRoute exact path="/updateStatusConfirmation/:userId" component={UpdateStatusPageConfirmation} />
                                    <WithoutSmithAIRoute exact path="/s/:group/:confNo" component={SimpleResultPage} />
                                    <WithoutSmithAIRoute exact path="/ap/:orgId/:docId" component={AptitudePassPage} />
                                    <WithoutSmithAIRoute exact path="/register" component={SchoolOpenRegisterPage} />
                                    <WithoutSmithAIRoute exact path="/SchoolTestingInformedConsent" component={SchoolTestingInformedConsent} />
                                    <WithoutSmithAIRoute exact path="/si/:orgId/:docId" component={GSDInsuranceFormPageV2}/>
                                    <WithoutSmithAIRoute exact path="/b/submit/:docId" component={PatientLinkSubmitSamplePage} />
                                    <WithoutSmithAIRoute exact path="/ev/:token" component={EmailVerificationPage}/>
                                    <WithSmithAIRoute exact path="/" component={WebsitePageOutsideReact} />
                                    <WithoutSmithAIRoute exact path="/faq" component={FaqPage} />
                                    <WithoutSmithAIRoute exact path="/book" component={BookingPage} />
                                    <WithoutSmithAIRoute exact path="/signup" component={FamilySignUpPage} />
                                    <Route exact path="/terms" component={TermsServicePage} />
                                    <Route exact path="/privacy" component={PrivacyNoticePage} />
                                    <Route exact path="/consent" component={PatientConsentPage} />
                                    <Route exact path="/grouptest" component={GroupTestContactFormPage} />
                                    <Route exact path="/errorpage" component={ErrorPage} />
                                    <WithoutSmithAIRoute exact path="/insurancequery" component={InsuranceFormPage} />
                                    <WithoutSmithAIRoute exact path="/release-notes" component={ReleaseNotesPage}/>
                                    <WithoutSmithAIRoute exact path="/instruction" component={SalivaCollectionInstructionPage} />
                                    <WithoutSmithAIRoute exact path="/samplereport" component={SampleReportPage} />
                                    <WithoutSmithAIRoute exact path="/samplereceipt" component={SampleReceiptPage} />
                                    <WithoutSmithAIRoute exact path="/pageexpired" component={LinkExpiredPage}/>
                                    <WithoutSmithAIRoute exact path="/gs/:group/:id" component={GroupBookingPage}/>
                                    <WithoutSmithAIRoute path="/forgetpassword"  component={ForgetPasswordPage}/>
                                    <WithoutSmithAIRoute path="/result"  component={TestResultPage}/>
                                    <TokenRoute exact path="/report/:token" sub="tokenReport" component={TestResultPage}/>
                                    <TokenRoute exact path="/book/change/:token" sub="book" component={RescheduleBookingPage}/>
                                    <TokenRoute exact path="/resetpassword/:token" sub="rsp" component={ResetPasswordPage}/>
                                    <AuthRoute exact path="/login" component={LoginPage} />
                                    <LoginRequriedRoute exact path="/crf" component={CRFFormPage} />
                                    <LoginRequriedRoute exact path="/helpdoc" component={HelpPage}/>
                                    <LoginRequriedRoute exact path="/addpatient" component={AddPatientPage} />
                                    <LoginRequriedRoute exact path="/portal" component={PortalHomePage} />
                                    <LoginRequriedRoute exact path="/approveresult" component={ApproveTestResultPage}/>
                                    <LoginRequriedRoute exact path="/uploadordersbycsv" component={UploadCsvPage}/>
                                    <LoginRequriedRoute exact path="/downloadcsv" component={DownloadCsvResultPage}/>
                                    <LoginRequriedRoute exact path="/getToken" component={GetGroupBookingTokenPage} />
                                    <LoginRequriedRoute exact path="/approveorder" component={PhysicianApproveOrderPage} />
                                    <LoginRequriedRoute exact path="/retailcalendar" component={RetailCalendarPage} />
                                    <LoginRequriedRoute exact path="/groupcalendar" component={GroupCalendarPage} />
                                    <LoginRequriedRoute exact path="/insurancequeryadmin" component={InsuranceQueryAdminPage} />
                                    <LoginRequriedRoute exact path="/getonetimecoupon" component={GetOneTimeCouponPage} />
                                    <LoginRequriedRoute exact path="/checkinpatients" component={CheckInPatientsPage} />
                                    <LoginRequriedRoute exact path="/accountsettings" component={AccountSettingsPage} />
                                    <LoginRequriedRoute exact path="/uploadfiles" component={UploadFilesPage}/>
                                    <LoginRequriedRoute exact path="/editpatient" component={EditPatientPage}/>
                                    <LoginRequriedRoute exact path="/groupOrder" component={GroupOrderPage}/>
                                    <LoginRequriedRoute exact path="/insurancerecordadmin" component={InsuranceRecordAdminPage}/>
                                    <LoginRequriedRoute exact path="/usermanagement" component={UserManagementPage}/>
                                    <LoginRequriedRoute exact path="/editInstitution" component={EditInstitutionPage}/>
                                    <LoginRequriedRoute exact path="/samplecollection" component={SampleCollectionPage}/>
                                    <LoginRequriedRoute exact path="/resulthistory" component={ResultHistoryPage}/>
                                    <LoginRequriedRoute exact path="/familyRegistrationQRcode" component={FamilyRegistrationQRCodePage}/>
                                    <LoginRequriedRoute exact path="/family-members" component={FamilyMemberPage}/>
                                    <LoginRequriedRoute exact path="/cronjobstatus" component={CronJobStatusPage}/>
                                    <LoginRequriedRoute exact path="/family-test-result" component={FamilyTestResultsPage}/>
                                    <LoginRequriedRoute exact path="/family-notification" component={FamilyNotificationSettingsPage}/>
                                    <LoginRequriedRoute exact path="/family-insurance" component={FamilyInsurancePage}/>
                                    <LoginRequriedRoute exact path="/recoveruseraccount" component={RecoverUserAccountPage}/>
                                    <LoginRequriedRoute exact path="/getOrderReceipt" component={GetOrderReceiptPage} />
                                    <Route component={PageNotFound} />
                                </Switch>
                            </Suspense>
                        </ErrorBoundary>

                        {HideNavBar(location.pathname) ? <div/> : <Footer/>}

                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </LocalizationProvider>

                <IdleTimer />
            </Provider>
        </MuiThemeProvider>
    </>;
}

const WR = withRouter(App);
const AppWithRouter = () =>(<BrowserRouter><WR/></BrowserRouter>);

export default AppWithRouter;
