import  { useState, cloneElement, Fragment } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CssBaseline from '@mui/material/CssBaseline'
import withStyles from '@mui/styles/withStyles';
import Hidden from '@mui/material/Hidden'
// import Drawer from '@mui/material/Drawer'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
// import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { connect } from 'react-redux'
import { logoutUser } from '../redux/actions/userAction'
import {
    HomeIcon,
    TubeIcon,
    ZoomQuestionIcon, //PersonCallIcon,
    CallCircleIcon,
    ProfileIcon,
} from '../components/Icons'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import ContactMailIcon from '@mui/icons-material/ContactMail'
// import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Avatar from '@mui/material/Avatar'
// import MyIcon from '../images/icon.svg'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Logout from '@mui/icons-material/Logout'
import ListItemIcon from '@mui/material/ListItemIcon'
import MuiHomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { WEBSITE_URL } from '../util/config';

function ElevationScroll(props) {
    const { children } = props
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    })
    return cloneElement(children, {
        elevation: trigger ? 1 : 0,
    })
}


const styles = (theme) => ({
    ...theme.customStyle,
    navBarTitle: {
        textAlign: 'center',
        flexGrow: 4,
    },
    navBar: {
        width: '95%',
        maxWidth: '1250px',
        margin: 'auto',
        padding: 0,
        minHeight: '45px',
        backgroundColor: '#dcff57',
    },
    navBarLink: {
        flexGrow: 0,
        borderRadius: 20,
        margin: '0 1px 0 1px',
        padding: '5px 20px 5px 20px',
        color: '#0b002b',
        textTransform:'none',
        transform: 'translate(0,0)',
        textDecoration: 'none',
        // transition: theme.transitions.create('transform', {
        //     easing: theme.transitions.easing.easeInOut,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        '&:hover': {
            // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',                        
            background: 'rgba(0, 0, 0, 0.04)',
            transform: 'translate(0,-1px)',
            // transition: theme.transitions.create('transform', {
            //     easing: theme.transitions.easing.easeInOut,
            //     duration: theme.transitions.duration.enteringScreen,
            // }),
        },
    },   
})


const UserMenu = ({
    user,
    menuAnchor,
    handleMenuClose,
    logoutUser
}) => {

    return ( <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}       
        transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
        }}
        anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
        }}
        style={{ zIndex: 1201 }}
        PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 28,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform:
                        'translateY(-50%) rotate(45deg)',
                },
            },
        }}
    >
        <MenuItem
            component={Link}
            to={'/portal'}
            onClick={handleMenuClose}
        >
            <ListItemIcon>
                <MuiHomeIcon fontSize="small" />
            </ListItemIcon>
            Portal Home
        </MenuItem>
        <Divider />
        <MenuItem
            component={Link}
            to={'/accountsettings'}
            onClick={handleMenuClose}
        >
            <ListItemIcon>
                <PersonIcon  fontSize="small" />
            </ListItemIcon>
            User Settings
        </MenuItem>

        {
            (user && ['admin','service','reception'].includes(user.role)) &&             
                <MenuItem
                component={Link}
                to={'/helpdoc'}
                onClick={handleMenuClose}
              >
                  <ListItemIcon>
                      <HelpOutlineIcon  fontSize="small" />
                  </ListItemIcon>
                  Help
              </MenuItem>
        }
        {
            (user && ['admin','service','reception'].includes(user.role)) &&             
         <MenuItem
                component={Link}
                to={'/release-notes'}
                onClick={handleMenuClose}
              >
                  <ListItemIcon>
                      <PostAddIcon  fontSize="small" />
                  </ListItemIcon>
                  Relase Notes
              </MenuItem>
        }

        <Divider />
        <MenuItem
            onClick={() => {
                handleMenuClose()
                logoutUser()
            }}
        >
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Log Out
        </MenuItem>
    </Menu>)
}


function NavBar(props) {
    const { classes, user, logoutUser } = props;

    // state of drawer
    const [state, setState] = useState(false)

    const toggleDrawer = (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }
        setState(!state)
    }

    const [menuAnchor, setMenuAnchor] = useState(null)

    const navItems = user.authenticated
        ? [['HOME', HomeIcon, '/portal']]
        : [
              ['Home', HomeIcon, '/'],
              ['Order', TubeIcon, '/book'],
              ['FAQ', ZoomQuestionIcon, '/faq'],
              ['Result', CloudDownloadRoundedIcon, '/result'],
              ['Group Test', ContactMailIcon, '/grouptest'],
              ['Insurance', ContactMailIcon, '/insurancequery'],
          ]

    const navBarItems = navItems.map(([text, Icon, link], index) => {
        return (index === 0) ? <>

            {user.authenticated ? (
                <Button
                    className={classes.navBarLink}
                    component={Link}
                    to={'/portal'}
                    key={text}
                    color="inherit">
                        <Typography
                            varient="h6"
                            style={{ textTransform: 'none' }}>
                                {text}
                        </Typography>
                </Button>
            ) : (
                <a className={classes.navBarLink} component={Link} href={WEBSITE_URL} key={text} color="inherit">
                    <Typography varient="h6" style={{ textTransform: 'none' }}>
                        {text}
                    </Typography>
                </a>
            )}

        </> : (
            <Button
                className={classes.navBarLink}
                component={Link}
                to={link}
                // startIcon={<Icon/>}
                key={text}
                color="inherit">
                    <Typography
                        varient="h6"
                        style={{ textTransform: 'none' }}>
                            {text}
                    </Typography>
            </Button>
        );
    });

    const menuItems = (
        <div onClick={toggleDrawer} onKeyDown={toggleDrawer}>
            <List>
                <Toolbar />

                {navItems.map(([text, Icon, link], idx) => {
                    return (idx === 0) ? (
                        <a component={Link} href={WEBSITE_URL} key={text} color="inherit" style={{ 'text-decoration': 'none' }}>
                            <Fragment key={text}>
                                <ListItem style={{ width: '100%' }}>
                                    <Button
                                        className={classes.navBarLink}
                                        style={{ width: '100%', justifyContent: 'left' }}
                                        color="inherit"
                                        startIcon={<Icon fill="#000" />}>
                                        <Typography varient="h6" style={{ textTransform: 'none' }}>
                                            {text}
                                        </Typography>
                                    </Button>
                                </ListItem>
                            </Fragment>
                        </a>
                    ) : (
                        <Fragment key={text}>
                            <ListItem style={{ width: '100%' }}>
                                <Button
                                    className={classes.navBarLink}
                                    style={{ width: '100%', justifyContent: 'left' }}
                                    color="inherit"
                                    component={Link}
                                    to={link}
                                    startIcon={<Icon fill="#000" />}>
                                        <Typography varient="h6" style={{ textTransform: 'none' }}>
                                                {text}
                                        </Typography>
                                </Button>
                            </ListItem>
                            {/* {idx === navItems.length - 2 ? <Divider /> : null} */}
                        </Fragment>
                    )
                })}
            </List>
        </div>
    )

    const handleMenuClose = () => {
        setMenuAnchor(null)
    }

    const portalButtonProps = user.authenticated
        ? {
              onClick: (e) => setMenuAnchor(e.currentTarget),
          }
        : {
              component: Link,
              to: '/login',
          }    
    return <>
        <CssBaseline />
        <ElevationScroll {...props}>
            {/* conditionally set the zIndex so that AppBar show in front of the drawer menu */}
            {/* I don't remember why i need to do it conditionally?? <AppBar style={{zIndex:state?1400:null}}> */}
            {/* If don't then all item will be below this. */}
            {/* <AppBar style={{zIndex:1400}}> */}
            <AppBar style={{ zIndex: 1201 }}>
                <Toolbar className={classes.navBar}>
                    <Hidden mdUp>
                        <IconButton
                            className={classes.navBarLink}
                            color="inherit"
                            onClick={toggleDrawer}
                            size="large">
                            {state ? <CloseRoundedIcon /> : <MenuIcon />}
                        </IconButton>
                        <Drawer
                            anchor="top"
                            open={state}
                            onClose={toggleDrawer}
                        >
                            {menuItems}
                        </Drawer>
                    </Hidden>

                    <Hidden mdDown>{navBarItems}</Hidden>

                    <div className={classes.navBarTitle} />
                    {
                        // only show the call us button if the user is not authenticated
                        !user.authenticated && (
                            <Tooltip title="Call Us">
                                <Button
                                    className={classes.navBarLink}
                                    
                                    href="tel:805-409-9669"
                                    startIcon={<CallCircleIcon />}
                                >
                                        <Hidden mdDown>
                                            (805)-409-9669
                                        </Hidden>                                        
                                </Button>
                            </Tooltip>
                        )
                    }
                    <Tooltip
                        title={
                            user.authenticated
                                ? `Signed in as ${user.name}`
                                : 'Portal'
                        }
                    >
                        <IconButton className={classes.navBarLink} {...portalButtonProps} size="large">
                            {/* <SvgIcon component={MyIcon} ></SvgIcon> */}
                            {user.authenticated ? (
                                <Avatar
                                    style={{
                                        width: 32,
                                        height: 32,
                                        margin: -4,
                                        backgroundColor:
                                            'rgba(200, 218, 211, 0.5)',
                                        color: '#FFF',
                                    }}
                                    alt={user.name}
                                    src={user.avatarUrl || '/'}
                                />
                            ) : (
                                <ProfileIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    
                </Toolbar>
            </AppBar>
        </ElevationScroll>
        {/* additional Toobar to be able to take the tool bar space on page. */}
        <Toolbar className={classes.navBar}/>
        <UserMenu
            menuAnchor={menuAnchor}
            handleMenuClose={handleMenuClose}
            logoutUser={logoutUser}
            user={user}
        />
    </>;
}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = {
    logoutUser,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(NavBar))
